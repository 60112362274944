import React, { useState } from 'react';
import pako from 'pako';
import base64js from 'base64-js';

import * as styles from './pendo-jzb-decoder.module.css';
import Textarea from '../Textarea/textarea';

function decode(value) {
  if (value.length === 0) {
    return '{}';
  }
  try {
    const unpaddedJzbString = value.substr(
      0,
      value.length - (value.length % 4)
    );

    // base64 decode jzb string -> zlib binary
    const byteArray = base64js.toByteArray(unpaddedJzbString);

    // zlib inflate (decompress) binary -> JSON string
    const jsonStr = pako.inflate(byteArray, { to: 'string' });

    // Make it nice to read
    return JSON.stringify(JSON.parse(jsonStr), null, 2) + '';
  } catch (e) {
    return '{}';
  }
}

const PendoJzbDecoder = () => {
  const [jzb, setJzb] = useState('');
  const decoded = decode(jzb);

  return (
    <div className="container">
      <Textarea
        label="JZB query param"
        placeholder="Paste jzb query param here..."
        name="jzb-input"
        autoComplete="off"
        onChange={(ev) => setJzb(ev.target.value)}
        rows="6"
        value={jzb}
      />

      <pre className={styles.decoded}>{decoded}</pre>
    </div>
  );
};

export default PendoJzbDecoder;
